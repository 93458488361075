// Imports
@import "general";

html, body {
    height: 100%;
}

img {
    width: auto;
    height: auto;
}

// Lead section
.lead {
    padding: 135px 0 0;
    background-color: $base-color1;

    @include breakpoint(laptop) {
        padding: 70px 80px;
    }

    .page-row-blocks {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-shadow: 0 2px 32px 0 rgba(0,0,0,0.08);
        -moz-box-shadow: 0 2px 32px 0 rgba(0,0,0,0.08);
        box-shadow: 0 2px 32px 0 rgba(0,0,0,0.08);

        @include breakpoint(tablet) {
            display: block;
            -webkit-box-shadow: none;
            -moz-box-shadow: none;
            box-shadow: none;

            .image {
                max-height: 400px;
                box-shadow: 0 2px 32px 0 rgba(0,0,0,0.08);
            }
        }

        .image {
            padding: 0;

            @include breakpoint(tablet) {
                padding: 0;
                z-index: 1;
                overflow: hidden;
            }

            &:before {
                content: '';
                position: absolute;
                top: 0;
                bottom: 5px;
                left: 15px;
                right: 15px;
                margin: auto;
                width: 88%;
                height: 91%;
                box-shadow: 0 0 0 8px rgba(200, 176, 175, .502);

                @include breakpoint(tablet) {
                    width: 87%;
                    height: 83%;
                }
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            @include breakpoint(mobile) {
                -webkit-box-shadow: none;
                -moz-box-shadow: none;
                box-shadow: none;

                &:before {
                    width: 82%;
                    height: 83%;
                }
            }
        }

        .content {
            background-color: #fff;
            padding: 130px 140px;

            @include breakpoint(laptop) {
                padding: 70px 80px;
            }

            @include breakpoint(tablet-landscape) {
                padding: 70px 50px;
            }

            @include breakpoint(tablet) {
                padding: 90px 90px 45px;
                margin-top: -40px;
                box-shadow: 0 2px 32px 0 rgba(0,0,0,0.08);
            }

            @include breakpoint(mobile) {
                padding: 70px 30px 45px;
            }

            h2 {
                margin: 0;

                @include breakpoint(mobile) {
                    font-size: 32px;
                    line-height: 38px;
                }
            }

            p {
                max-width: 490px;

                @include breakpoint(tablet) {
                    max-width: none;
                }

                .button {
                    margin-top: 20px;

                    @include breakpoint(tablet-landscape) {
                        margin-top: 10px;
                    }
                }
            }
        }
    }
}

// Advice section
.advice {
    margin-top: -170px;
    padding: 250px 0 225px;
    background-color: $base-color2;
    color: #fff;

    @include breakpoint(tablet-landscape) {
        padding-bottom: 175px;
    }

    @include breakpoint(tablet) {
        padding-bottom: 90px;
    }

    h2 {
        color: #fff;
    }

    // Advice text above
    .advice-text {
        .inner {
            padding: 105px 95px;

            @include breakpoint(tablet-landscape) {
                padding: 50px 30px;
            }

            .underline:after {
                background-color: #393031;
            }

            @include breakpoint(laptop) {
                padding: 80px 50px;

                h2 {
                    margin: 0;
                }
            }

            @include breakpoint(tablet) {
                padding: 50px 40px 60px;

                h2 {
                    margin: 0;
                }
            }

            @include breakpoint(mobile) {
                padding: 50px 30px 10px;
            }

            ul {
                padding: 0;
                margin: 0;
                list-style-type: none;

                @include breakpoint(tablet) {
                    margin-top: 30px;
                }

                @include breakpoint(mobile) {
                    margin-top: 0;
                }

                li {
                    padding: 40px 30px 40px 80px;
                    border: 1px solid lighten($base-color2, 5%);
                    border-top: 0;
                    position: relative;

                    span.underline:after {
                        content: none;
                    }

                    &:before {
                        content: '';
                        position: absolute;
                        left: 40px;
                        top: -6px;
                        bottom: 0;
                        margin: auto;
                        background-image: url('../img/icons/arrow.svg');
                        width: 9px;
                        height: 17px;

                        @include breakpoint(mobile) {
                            left: 5px;
                        }
                    }

                    &:first-child {
                        border-top: 1px solid lighten($base-color2, 5%);
                    }

                    @include breakpoint(mobile) {
                        padding: 20px 10px 20px 40px;
                        border: none;

                        &:first-child {
                            border: none;
                        }
                    }
                }
            }
        }

        .border:after {
            content: '';
            width: 100%;
            height: 138%;
            max-height: 790px;
            box-shadow: 0 0 0 10px lighten($base-color2, 2.2%);
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;

            @include breakpoint(tablet) {
                width: 100%;
                height: 100%;
            }

            @include breakpoint(mobile) {
                width: 94%;
                height: 190%;
                max-height: none;
                margin: auto;
                top: 0;
                bottom: auto;
            }
        }
    }

    // Advice blocks
    .advice-blocks {
        @include breakpoint(tablet) {
            padding-top: 30px;
        }

        .page-row-blocks {
            display: -webkit-box;
            display: -moz-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;

            .column {
                padding: 0;
                margin: 0 7px;
                background-image: url('../img/block-hover-bg.jpg');

                @include breakpoint(mobile) {
                    margin: 10px 5%;
                    width: 90%;
                }

                &.first img {
                    width: 56px;
                    height: 56px;
                }

                &.second img {
                    width: 82px;
                    height: 43px;
                }

                &.third img {
                    width: 46px;
                    height: 54px;
                }

                .inner {
                    width: 100%;
                    height: 100%;
                    background-color: #fff;
                    padding: 120px 40px 110px;
                    text-align: center;
                    -webkit-transition: all .3s;
                    -moz-transition: all .3s;
                    -ms-transition: all .3s;
                    -o-transition: all .3s;
                    transition: all .3s;

                    @include breakpoint(tablet-landscape) {
                        min-height: 291px; // Flex no workie safari
                    }

                    @include breakpoint(tablet) {
                        padding: 120px 10px 110px;
                    }

                    @include breakpoint(mobile) {
                        min-height: auto;
                    }

                    p {
                        margin: 0;
                        line-height: 0;

                        .button {
                            line-height: 27px;
                        }
                    }

                    img {
                        position: absolute;
                        top: 50px;
                        left: 0;
                        right: 0;
                        margin: auto;
                    }

                    h3 {
                        margin: 0;
                        font-size: 19px;
                        line-height: 23px;
                        margin-top: 15px;

                        @include breakpoint(tablet) {
                            font-size: 18px;
                        }
                    }

                    .button {
                        margin: 20px auto 0;
                        position: absolute;
                        left: 0;
                        right: 0;
                        bottom: 35px;
                        width: 50%;

                        @include breakpoint(laptop) {
                            width: 60%;
                        }

                        @include breakpoint(tablet-landscape) {
                            width: 60%;
                        }

                        @include breakpoint(tablet) {
                            padding: 13px 55px 13px 5px;
                            font-size: 16px;
                            width: 80%;
                        }

                        @include breakpoint(mobile) {
                            width: 60%;
                        }
                    }

                    &:hover {
                        background-color: transparent;

                        img {
                            -ms-filter: brightness(0) invert(1);
                            filter: brightness(0) invert(1);
                        }

                        .button {
                            color: #fff;
                            border-color: #fff;

                            &:before, &:after {
                                background-color: #fff;
                            }
                        }
                    }
                }
            }
        }

        &.alt {
            padding: 150px 0;
            background-color: $base-color1;

            .column {
                text-align: center;

                .inner {
                    background-color: #fff;
                    padding: 40px 20px 30px;
                    box-shadow: 0 0 32px 5px rgba(0, 0, 0, .075);
                }
            }
        }
    }
}

// Appearance section
.appearance {
    padding: 0 0 150px;
    margin-top: -85px;

    @include breakpoint(laptop) {
        padding: 0 0 80px;
    }

    @include breakpoint(tablet-landscape) {
        padding: 0 0 60px;
    }

    @include breakpoint(tablet) {
        padding: 40px 0 70px;
    }

    .column {
        .inner {
            padding: 245px 10px 0 0;

            @include breakpoint(laptop) {
                padding: 145px 10px 0 0;
            }

            @include breakpoint(tablet-landscape) {
                padding: 145px 10px 0 0;
            }

            @include breakpoint(tablet) {
                padding: 60px 10px 0 0;
            }

            h2 {
                margin: 0 0 50px;

                @include breakpoint(laptop) {
                    font-size: 36px;
                    line-height: 43px;
                }

                @include breakpoint(tablet-landscape) {
                    line-height: 46px;
                    margin: 0 0 30px;
                }
            }

            p .button {
                margin-top: 25px;
            }
        }

        .image-wrapper {
            &:before {
                content: '';
                position: absolute;
                top: 0;
                bottom: 5px;
                left: 15px;
                right: 15px;
                margin: auto;
                width: 88%;
                height: 91%;
                box-shadow: 0 0 0 8px rgba(200, 176, 175, .502);

                @include breakpoint(tablet) {
                    width: 87%;
                    height: 83%;
                }
            }

            img {
                width: 100%;
                height: 100%;
            }
        }

        @include breakpoint(tablet) {
            &.image {
                padding: 0;
                z-index: 1;
                overflow: hidden;
                max-height: 400px;
            }
        }
    }
}

// Testimonial section
.testimonial {
    text-align: center;
    background-color: $base-color1;
    padding: 0 0 90px;

    @include breakpoint(tablet) {
        padding-bottom: 40px;
    }

    .column {
        .inner {
            margin-top: -25px;

            p:first-child {
                margin: 0;
            }

            h3 {
                margin-top: 0;

                @include breakpoint(mobile) {
                    font-size: 25px;
                    margin-top: 9px;
                    margin-bottom: 10px;
                }

                img {
                    width: 100%;
                    max-width: 52px;
                    display: inline-block;
                    border-radius: 0;
                    margin-bottom: -10px;

                    @include breakpoint(mobile) {
                        max-width: 38px;
                    }
                }
            }

            img.portrait {
                width: 125px;
                height: 125px;
                border-radius: 100%;
                -webkit-border-radius: 100%;
                border: 5px solid $base-color3;
                margin-top: 30px;
            }

            .testimonial-text {
                max-width: 63%;
                margin: 30px auto;

                @include breakpoint(mobile) {
                    max-width: none;
                }
            }

            .author {
                margin-top: 40px;
                font-size: 36px;
                font-family: $alt-heading-font-family;
                line-height: 32px;
                letter-spacing: .075em;
            }

            .caption {
                font-size: 21px;
                margin-top: 10px;

                .right {
                    color: #b9b9b9;
                }
            }

            .button {
                margin-top: 45px;
            }
        }
    }

    img {
        -webkit-border-radius: 50%;
        border-radius: 50%;
    }
}

// Call to action section
.call-to-action {
    width: 100%;
    height: auto;
    padding: 0;
    background-color: $base-color4;
    position: relative;

    .flex {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;

        .col {
            padding: 0;

            @include breakpoint(tablet) {
                flex: 1 1 50%;

                &:first-child {
                    flex: 1 1 20%;
                }
            }

            img {
                object-fit: cover;
                height: 100%;
                max-width: none;
                width: 100%;
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                margin: auto;

                @include breakpoint(mobile) {
                    position: unset;
                }
            }

            .text-block {
                padding: 150px 140px;
                color: #fff;

                @include breakpoint(laptop) {
                    padding: 90px;
                }

                @include breakpoint(tablet-landscape) {
                    padding: 50px 60px;
                }

                @include breakpoint(tablet) {
                    padding: 60px 70px;
                }

                @include breakpoint(mobile) {
                    padding: 30px;
                }

                h2 {
                    margin: 0;
                    color: #fff;

                    @include breakpoint(tablet) {
                        font-size: 36px;
                        br { display: none; }
                    }

                    @include breakpoint(mobile) {
                        font-size: 34px;
                        line-height: 41px;
                    }
                }

                .button {
                    margin-top: 30px;

                    @include breakpoint(mobile) {
                        margin: 5px 0 10px;
                    }
                }
            }
        }
    }
}

// Footer
footer {
    padding: 210px 0 10px;
    background-color: $base-color2;
    color: #fff;

    @include breakpoint(tablet) {
        padding: 70px 0;
    }

    @include breakpoint(mobile) {
        padding: 70px 0 20px;
    }

    .middle-block {
        p {
            font-size: 16px;
            line-height: 20px;
            margin: 10px 0;

            &.m-t {
                margin-top: 30px;
            }
        }

        &:after {
            content: '';
            width: 100%;
            height: 110%;
            box-shadow: 0 0 0 10px #2a2425;
            position: absolute;
            top: -60px;
            left: 0;
            right: 0;
            bottom: 0;

            @include breakpoint(tablet) {
                width: 85%;
                height: 84%;
                margin: auto;
            }
        }
    }

    // Inner
    .inner {
        padding: 0 74px;

        @include breakpoint(laptop) {
            padding: 0 40px;
        }

        @include breakpoint(tablet-landscape) {
            padding: 0 20px;
        }

        @include breakpoint(tablet) {
            padding: 0 75px 50px;
        }

        @include breakpoint(mobile) {
            padding: 0 35px;
        }

        a {
            color: #fff;

            &:hover {
                color: $base-color3;
            }
        }

        ul {
            list-style-type: none;
            margin: 0;
            padding: 0;

            li {
                padding: 14px 0 10px 20px;
                border-bottom: 1px solid #3b3435;
                position: relative;

                &:before {
                    content: '';
                    background-size: cover;
                    position: absolute;
                    left: 0;
                    top: -2px;
                    bottom: 0;
                    margin: auto;
                    width: 6px;
                    height: 11px;
                    background-image: url('../img/icons/list-arrow.svg');
                }

                &:last-child {
                    border-bottom: 0;
                }
            }
        }

        h2, h3 {
            color: #fff;
            margin: 0;
        }

        h2 {
            font-size: 42px;
            line-height: 46px;
            margin-bottom: 5px;

            @include breakpoint(laptop) {
                font-size: 32px;
                line-height: 40px;
            }

            @include breakpoint(mobile) {
                font-size: 30px;
                line-height: 37px;
            }
        }

        h3 {
            font-size: 21px;
            font-family: $alt-heading-font-family;
        }

        address {
            font-style: normal;
            line-height: 27px;
            letter-spacing: .075em;

            // Phone & email icons
            .phone, .email {
                position: relative;
                padding-left: 30px;

                a {
                    color: #fff;
                    position: relative;
                    z-index: 1;

                    &:hover {
                        color: $base-color3;
                    }
                }

                &:before {
                    content: '';
                    background-size: cover;
                    position: absolute;
                    left: 0;
                    top: 0;
                    bottom: 0;
                }
            }

            .phone:before {
                width: 19px;
                height: 19px;
                background-image: url('../img/icons/phone.svg');
            }

            .email:before {
                width: 20px;
                height: 15px;
                background-image: url('../img/icons/mail.svg');
                opacity: .8;
            }
        }

        // Footer Logo
        .logo {
            text-align: center;
            margin-top: 40px;

            img {
                max-width: 115px;
                opacity: .1;
            }
        }
    }

    .copyright {
        p {
            text-align: center;
            opacity: .25;
            margin: 50px 0 20px;
            color: #b1b1b1;
            font-size: 17px;

            @include breakpoint(mobile) {
                margin-top: 30px;
                font-size: 15px;
            }

            a {
                color: #fff;

                &:hover {
                    color: #b1b1b1;
                }
            }
        }
    }

    @include breakpoint(tablet) {
        .middle-block {
            .inner {
                padding: 50px 75px;
            }
        }
    }

    @include breakpoint(mobile) {
        .middle-block {
            .inner {
                padding: 50px 35px;
            }
        }
    }
}

// Blog items section
.blogitems, .galleryitems {
    padding: 190px 0 65px;
    background-color: $base-color1;

    @include breakpoint(tablet) {
        padding: 120px 0 65px;
    }

    @include breakpoint(tablet) {
        padding: 100px 0 65px;
    }

    @include breakpoint(mobile) {
        padding: 100px 0 60px;
    }

    .flex {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        flex-wrap: wrap;
        -webkit-flex-wrap: wrap;

        &:after, &:before {
            content: none;
        }

        @include breakpoint(tablet-landscape) {
            display: block !important;
        }

        @include breakpoint(tablet) {
            display: -webkit-box !important;
            display: -moz-box !important;
            display: -ms-flexbox !important;
            display: -webkit-flex !important;
            display: flex !important;
            flex-wrap: wrap;
            -webkit-flex-wrap: wrap;
        }

        @include breakpoint(mobile) {
            display: block !important;
        }

    }

    .col {
        flex: 0 0 33.3333%;
        margin: 15px 0;

        @include breakpoint(tablet) {
            flex: 1 1 50%;
        }
    }

    // Blog
    .blog-item, .gallery-item {
        background-color: #fff;
        height: 100%;
        box-shadow: 0 2px 32px 0 rgba(0, 0, 0, .08);

        figure {
            position: relative;
            margin: 0;

            &:before {
                content: '';
                position: absolute;
                top: 30px;
                bottom: 30px;
                left: 15px;
                right: 15px;
                margin: auto;
                width: 88%;
                box-shadow: 0 0 0 8px rgba(200,176,175, .502);
            }

            img {
                width: 100%;
            }
        }

        .blog-inner, .gallery-inner {
            padding: 50px 45px 110px 45px;

            @include breakpoint(laptop) {
                padding: 40px 20px 90px 35px;
            }

            @include breakpoint(mobile) {
                padding: 20px 25px 115px;
            }

            h2 {
                font-size: 28px;
                line-height: 42px;
                z-index: 1;
                position: relative;
                margin-top: 0;
                word-break: break-word;

                @include breakpoint(laptop) {
                    font-size: 27px;
                    line-height: 36px;
                }
            }

            a.button {
                position: absolute;
                bottom: 50px;
                left: 45px;

                @include breakpoint(mobile) {
                    left: 25px;
                }
            }
        }
    }

    .pagination {
        text-align: center;
        padding: 55px 0 0;
    }
}

// About miranda
.about-miranda {
    padding: 180px 0 110px;
    background-color: $base-color1;

    @include breakpoint(mobile) {
        padding: 100px 0 80px;
    }

    .inner {
        h2 {
            margin-top: 0;
        }

        p {
            font-style: italic;

            .underline {
                content: none;
            }

            &:first-of-type::first-letter {
                font-style: normal;
                font-size: 58px;
                padding: 11px 11px 15px 0;
            }
        }

        h3 {
            font-family: $alt-heading-font-family;
            margin: 0;
            font-size: 32px;
        }
    }
}

// Contact
.contact {
    padding: 95px 0 70px;
    background-color: $base-color1;

    h2 {
        font-size: 42px;
        margin: 0;

        @include breakpoint(laptop) {
            font-size: 38px;
        }

        @include breakpoint(mobile) {
            max-width: 80%;
        }
    }

    .text-left {
        .inner {
            padding: 0 60px;
            margin-top: 135px;

            @include breakpoint(laptop) {
                padding: 0 25px 0 0;
            }

            @include breakpoint(tablet-landscape) {
                padding: 0 0;
            }

            @include breakpoint(tablet) {
                margin-top: 30px;
                margin-bottom: 50px;
            }

            @include breakpoint(mobile) {
                margin-top: 0;
                padding: 0;
            }

            img {
                margin: 70px 0 0 15px;

                @include breakpoint(tablet-landscape) {
                    max-width: 70%;
                }

                @include breakpoint(tablet) {
                    max-width: 50%;
                    display: block;
                    margin: auto;
                }
            }
        }
    }

    .contactform {
        background-color: #fff;
        box-shadow: 0 2px 32px 0 rgba(0, 0, 0, .08);

        .inner {
            padding: 135px 115px;

            @include breakpoint(laptop) {
                padding: 75px 60px;
            }

            @include breakpoint(tablet-landscape) {
                padding: 70px 40px;
            }

            @include breakpoint(tablet) {
                padding: 70px 50px;
            }

            @include breakpoint(mobile) {
                padding: 30px 15px;

                h2 {
                    font-size: 32px;
                    line-height: 38px;
                    max-width: none;
                }
            }

            p:first-of-type::first-letter {
                font-size: 51px;
                padding: 15px 9px 15px 0;

                @include breakpoint(mobile) {
                    padding: 15px 9px 10px 0;
                }
            }

            .errorField {
                border: 1px solid rgba(255,0,0, .3) !important;
                box-shadow: 7px 8px 0 0 rgba(255,0,0, .12) !important;
            }

            input[type="text"], input[type="email"], input[type="tel"], textarea {
                background-color: #fff;
                width: 100%;
                padding: 15px 20px;
                border: 1px solid #f0e4e4;
                box-shadow: 7px 8px 0 0 #f0e4e4;
                margin: 12.5px 0;
                outline: none;
                font-family: $base-font-family;
                font-size: 18px;
                color: rgba(0, 0, 0, .7);

                &::placeholder {
                    color: rgba(0, 0, 0, .5);
                }
            }

            textarea {
                max-width: 100%;
                min-width: 100%;
                min-height: 137px;
                max-height: 520px;

                &::-webkit-scrollbar-track {
                    -webkit-box-shadow: none;
                    background-color: #ffffff;
                    box-shadow: none;
                }

                &::-webkit-scrollbar {
                    width: 15px;
                    background-color: #ffffff;
                }

                &::-webkit-scrollbar-thumb {
                    background-color: $base-color1;
                    box-shadow: inset 1px 1px 2px #c5b7b7;
                    border: 4px solid #fff;
                    border-radius: 15px;
                }
            }

            .button {
                outline: none;
                margin: 12.5px 0 0;
                cursor: pointer;

                @include breakpoint(mobile) {
                    font-size: 16px;
                }

                .preloader {
                    display: none;
                }

                &._loading {
                    padding: 15px 45px;

                    &:before, &:after {
                        content: none;
                    }

                    &:hover {
                        border-color: $base-color3;
                    }

                    .preloader-hide {
                        display: none;
                    }

                    .preloader {
                        display: block;
                        text-align: center;
                        font-size: 0;

                        span {
                            display: inline-block;
                            position: relative;
                            vertical-align: middle;
                            width: 12px;
                            height: 12px;
                            line-height: 12px;

                            &:before {
                                content: "";
                                display: inline-block;
                                vertical-align: middle;
                                width: 6px;
                                height: 6px;
                                border-radius: 50%;
                                background: #dec7c7;
                            }

                            &:first-child {
                                &:before {
                                    animation: preloader .9s linear infinite;
                                }
                            }
                            &:nth-child(2) {
                                &:before {
                                    animation: preloader .9s linear infinite .35s
                                }
                            }
                            &:nth-child(3) {
                                &:before {
                                    animation: preloader .9s linear infinite .55s
                                }
                            }
                        }
                    }
                }

                @include keyframes(preloader) {
                    0% { box-shadow: 0 0 0 0 #dec7c7; }
                    50% { box-shadow: 0 0 0 3px #dec7c7; }
                    100% { box-shadow: 0 0 0 0 #dec7c7; }
                }
            }
        }
    }
}

// Basic text page & blog items
.basic-text, .blogitem {
    padding: 180px 0;
    background-color: $base-color1;

    @include breakpoint(tablet) {
        padding: 80px 0 10px;
    }

    @include breakpoint(mobile) {
        padding: 80px 0 50px;
    }

    h2 {
        word-break: break-word;
    }

    h3 {
        margin-top: 80px;
        font-size: 30px;
        font-weight: 600;
    }

    p {
        margin: 30px 0;

        .underline:after {
            content: none;
        }

        &::first-letter {
            float: left;
            font-size: 58px;
            padding: 11px 11px 15px 0;
        }
    }

    ul {
        list-style: none;
        padding: 0;
        margin: 0;

        li {
            padding: 14px 0 10px 20px;
            position: relative;
            letter-spacing: .075em;
            line-height: 27px;

            &:before {
                content: '';
                background-size: cover;
                position: absolute;
                left: 0;
                top: 0;
                bottom: 0;
                margin: 17px auto;
                width: 6px;
                height: 11px;
                background-image: url('../img/icons/list-arrow.svg');
            }
        }
    }

    ol {
        padding: 0 0 0 15px;
        margin: 0;

        li {
            padding: 10px;
            font-size: 18px;
            letter-spacing: .075em;
            line-height: 27px;
        }
    }
}

// Blog item only
.blogitem {
    padding: 100px 0 80px;

    .button.return {
        padding: 12px 20px 12px 70px;
        font-size: 14px;

        &:before {
            left: 50px;
        }

        &:after {
            left: 20px;
            -webkit-transform: rotate(180deg);
            -moz-transform: rotate(180deg);
            -ms-transform: rotate(180deg);
            -o-transform: rotate(180deg);
            transform: rotate(180deg);
        }
    }

    p {
        &::first-letter {
            float: none;
            font-size: inherit;
            padding: 0;
        }
        &:first-of-type::first-letter {
            float: left;
            font-size: 58px;
            padding: 11px 11px 15px 0;
        }
    }

    img {
        width: 100%;
        margin-top: 30px;
        box-shadow: 0 2px 36px 0 rgba(0, 0, 0, 0.17);
    }
}

// What, where & how block
.what-where-how {
    background-color: $base-color1;

    .page-row-blocks {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;

        @include breakpoint(tablet) {
            display: block;
        }
    }

    .left {
        padding: 0;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        @include breakpoint(tablet) {
            min-height: 450px;
            z-index: 1;
        }

        @include breakpoint(mobile) {
            min-height: auto;
        }
    }

    .right {
        padding: 130px 100px 80px;
        background-color: $base-color2;

        @include breakpoint(laptop) {
            padding: 10px 50px 40px;
        }

        @include breakpoint(tablet-landscape) {
            padding: 10px 30px 40px;
        }

        @include breakpoint(tablet) {
            padding: 130px 50px 80px;
            margin-top: -80px;
        }

        @include breakpoint(mobile) {
            padding: 20px 0 50px;
            margin-top: -15px;
        }

        h2 {
            font-size: 48px;
            color: #fff;

            .underline:after {
                background-color: #342c2d;
            }

            @include breakpoint(tablet) {
                font-size: 43px;
            }

            @include breakpoint(mobile) {
                font-size: 38px;
                padding: 0 25px;
            }
        }

        table {
            border-collapse: collapse;
            color: #fff;
            font-family: $base-font-family;

            @include breakpoint(tablet) {
                width: 100%;
            }

            tr {
                border: 1px solid #fff;
                border-bottom: none;

                @include breakpoint(mobile) {
                    border: 1px solid rgba(204, 172, 170, 0.17);
                    border-left: none;
                    border-right: none;
                    border-bottom: none;
                }

                &:last-child {
                    border-bottom: 1px solid #fff;

                    @include breakpoint(mobile) {
                        border-bottom: none;
                    }
                }

                td {
                    padding: 40px 35px;

                    @include breakpoint(tablet) {
                        padding: 30px 35px;
                    }

                    @include breakpoint(mobile) {
                        padding: 20px 25px;
                    }

                    &:first-child {
                        padding: 40px 0 40px 35px;
                        text-align: center;

                        @include breakpoint(tablet) {
                            padding: 30px 0 30px 35px;
                        }

                        @include breakpoint(mobile) {
                            padding: 20px 0 17px 25px;
                        }

                        img {
                            width: 100%;
                            min-width: 23px;
                            max-width: 23px;

                            @include breakpoint(mobile) {
                                width: 19px;
                            }
                        }
                    }
                }
            }
        }

        p {
            margin: 40px 0 0;
            color: #9a9a9a;
            max-width: 85%;
            font-size: 15px;

            @include breakpoint(mobile) {
                max-width: none;
                padding: 0 15px;
                text-align: center;
            }
        }
    }
}

// Shop subscription
.shop-subscription {
    padding: 380px 0 180px;
    background-color: #fff;
    margin-top: -230px;

    @include breakpoint(tablet) {
        padding: 230px 0 70px;
    }

    @include breakpoint(mobile) {
        h2 {
            font-size: 26px;
        }
    }

    .inner {
        max-width: 830px;

        @include breakpoint(tablet) {
            max-width: none;
        }
    }

    .button {
        margin-top: 10px;
    }
}

// Testimonials / Reviews
.testimonials {
    padding: 105px 0 100px;
    background-color: $base-color1;

    @include breakpoint(tablet) {
        padding: 60px 0 100px;
    }

    @include breakpoint(mobile) {
        padding: 150px 0 80px;
    }

    .col:first-child {
        margin-top: 80px;

        @include breakpoint(tablet) {
            margin-top: 0;
        }
    }

    .testimonial-block {
        background-color: #fff;
        margin: 17.5px 0;

        .testimonial-block-inner {
            padding: 55px;
            box-shadow: 0 2px 32px 0 rgba(0, 0, 0, .08);
            text-align: center;

            @include breakpoint(tablet) {
                padding: 55px 30px;
            }

            img {
                width: 125px;
                height: 125px;
                border-radius: 100%;
                -webkit-border-radius: 100%;
                border: 5px solid #c7afae;
                margin-bottom: 10px;
            }

            .author {
                margin-top: 40px;
                font-size: 36px;
                font-family: "Moanhand",serif;
                line-height: 32px;
                letter-spacing: .075em;
            }

            .caption {
                font-size: 21px;
                margin-top: 10px;

                .right {
                    color: #b9b9b9;
                }
            }
        }
    }
}

// Thanks page
.thanks-message {
    padding: 100px 0 120px;

    @include breakpoint(laptop) {
        padding: 80px 0 100px;
    }

    @include breakpoint(landscape-tablet) {
        padding: 80px 0 100px;
    }

    @include breakpoint(tablet) {
        padding: 80px 0 100px;
    }
}
