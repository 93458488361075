// Font smoothing
@if ($smoothing == true) {
	body {
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}
}

/*
    Fonts: Open Sans, Couture, Moanhond, Din 2014
*/ //@import url("https://use.typekit.net/nws4zyv.css"); (loaded in sitekick)

// Moanhand
@font-face {
    font-family: 'Moanhand';
    src: url('../fonts/moanhand-webfont.eot');
    src: url('../fonts/moanhand-webfont.eot?#iefix') format('embedded-opentype'),
    url('../fonts/moanhand-webfont.woff2') format('woff2'),
    url('../fonts/moanhand-webfont.woff') format('woff'),
    url('../fonts/moanhand-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

// Couture
@font-face {
    font-family: 'CoutureBold';
    src: url('../fonts/couture-webfont.woff2') format('woff2'),
    url('../fonts/couture-webfont.woff') format('woff'),
    url('../fonts/couture-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

// Font weights
$weight-light: 			300;
$weight-regular: 		400;
$weight-bold: 			700;

// Font settings
$base-font-family: 		'fanwood', serif;
$alt-base-font-family:  'din-2014', sans-serif;
$base-font-weight: 		$weight-regular;
$base-font-size: 		18px;
$base-font-color1: 		#000000;
$base-font-color2: 		#FFFFFF;

// Headings
$heading-h1-size:		70px;
$heading-h2-size:		42px;
$heading-h3-size:		36px;
$heading-h4-size:		24px;
$heading-h5-size:		18px;
$heading-h6-size:		16px;

$heading-font-family:	    'CoutureBold', sans-serif;
$alt-heading-font-family:   'Moanhand', serif;
$heading-letter-spacing:    .05em;
$heading-font-weight: 	    600;
$heading-font-color1:	    #000;
$heading-font-color2:	    #333;

// Button
$button-font-family: 'Open Sans', sans-serif;