.underline,
nav ul li a.active,
nav ul li .sub li.active a {
    position: relative;
    z-index: 1;

    &:after {
        content: '';
        position: absolute;
        background-color: #ecd6d4;
        width: 106%;
        height: 41%;
        bottom: -2px;
        left: -3%;
        right: 0;
        z-index: -1;
    }


    &.light-pink:after {
        background-color: #ddb6b4;
    }

    &.dark:after {
        background-color: #342c2d;
    }

    &.smaller:after {
        width: 104%;
        left: -2%;
    }
}

nav ul li .sub li.active a {
    &:after {
        width: 105%;
        left: -2.5%;
    }
}