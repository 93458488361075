.button {
	display: inline-block;
	padding: 13px 70px 13px 20px;
	border: 1px solid $base-color3;
	font-size: 15px;
	text-transform: uppercase;
	text-decoration: none;
	background-color: transparent;
	color: $base-color3;
	font-family: $button-font-family;
	letter-spacing: $heading-letter-spacing;
	position: relative;
	-webkit-transition: all .3s;
	-moz-transition: all .3s;
	-ms-transition: all .3s;
	-o-transition: all .3s;
	transition: all .3s;

	@include breakpoint(tablet) {
		padding: 15px 70px 15px 20px;
		font-size: 18px;
		margin-top: 0;
	}

	@include breakpoint(mobile) {
		font-size: 14px;
		line-height: 22px;
	}

	&:after {
		content: '';
		width: 9px;
		height: 17px;
		background-size: 100% 100%;
		background-color: $base-color3;
		-webkit-mask: url('../img/icons/arrow.svg') no-repeat 50% 50%;
		mask: url('../img/icons/arrow.svg') no-repeat 50% 50%;
		-webkit-mask-size: cover;
		mask-size: cover;
		position: absolute;
		top: 0;
		bottom: 0;
		margin: auto 0;
		right: 20px;
	}

	&:before {
		content: '';
		width: 1px;
		background-color: $base-color3;
		height: 100%;
		position: absolute;
		right: 50px;
		top: 0;
		bottom: 0;
	}

	&:hover {
		padding-right: 75px;
		color: #000;
		border-color: #000;

		&:before, &:after {
			background-color: #000;
		}
	}

	&.white {
		color: #fff;
		border-color: #fff;

		&:before, &:after {
			background-color: #fff;
		}
	}

	// fckn IE
	@media all and (-ms-high-contrast:none) {
		padding-right: 20px;

		&:before, &:after {
			content: none;
		}

		&:hover {
			padding: 13px 23px;
		}
	}
}