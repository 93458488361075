// Set accessibility testing true/false
$testing: false;
// Set font smoothing true/false
$smoothing: true;
// Set default gutter width (settings custom gutter width in _gutter.scss)
// True = bootstrap gutter width
$defaultGutter: true;

// Import plugins
@import "plugins/animations";
@import "plugins/bootstrap";

// Import mixins
@import "mixins/abs-pos";
@import "mixins/border-radius";
@import "mixins/box-sizing";
@import "mixins/breakpoints";
@import "mixins/keyframes";
@import "mixins/opacity";
@import "mixins/transition";
@import "mixins/gutter";
@import "mixins/shorthands";

// Import settings
@import "settings/testing";
@import "settings/colors";
@import "settings/font";
@import "settings/links";
@import "settings/gutter";

// Import elements
@import "elements/button";
@import "elements/underline";
@import "elements/header";
@import "elements/navigation";

// Box sizing
* {
	@include box-sizing(border-box);
}

// Body
body {
	background-color: 	$body-bg-color;
	font-family: 		$base-font-family;
	font-size: 			$base-font-size;
	font-weight: 		$base-font-weight;
	color: 				$base-font-color1;
	margin: 			0;
	padding: 			0;
}

// Headings
h1,h2,h3,h4,h5,h6 {
	font-family: 		$heading-font-family;
	font-weight: 		$base-font-weight;
	color: 				$heading-font-color1;
}

h1, h2, h3 {
	letter-spacing: $heading-letter-spacing;
}

h1 {
	font-size: $heading-h1-size;

	@include breakpoint(mobile) {
		font-size: 42px;
	}
}
h2 {
	font-size: $heading-h2-size;
	line-height: 54px;
	font-weight: $heading-font-weight;

	@include breakpoint(mobile) {
		font-size: 36px;
		line-height: 42px;
	}
}

h3 {font-size: $heading-h3-size;}
h4 {font-size: $heading-h4-size;}
h5 {font-size: $heading-h5-size;}
h6 {font-size: $heading-h6-size;}

// Paragraph
p {
	font-size: 18px;
	letter-spacing: .075em;
	line-height: 27px;
}

// First letter capitalize of paragraph
.first-letter-cap {
	p:first-of-type::first-letter {
		font-size: 65px;
		float: left;
		padding: 20px 10px 7px 0;
	}
}

// Container offset
._offset {
	max-width: 1240px;
}

// Links
a {
	// Default
	color: $link-color-default;
	text-decoration: $link-decoration-default;

	// Hover
	&:hover {
		color: $link-color-hover;
		text-decoration: $link-decoration-hover;
	}

	// Active
	&:active {
		//color: $link-color-active;
		text-decoration: $link-decoration-active;
	}

	// Visited
	&:visited {
		//color: $link-color-visited;
		text-decoration: $link-decoration-visited;
	}
}

// Responsive video iframes
.video-container {
	position: relative;
	padding-bottom: 56.25%; /*16:9*/
	height: 0;
	overflow: hidden;

	iframe,
	object,
	embed {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}

// Section
section {
	padding: 50px 0;
}

// Img
img {
	max-width: 100%;
}

// Clearspace
._clearspace {
	padding: 0!important;
	margin: 0!important;
}

// Container
.container {
	@media (min-width: 1800px) {
		width: 1610px;
	}

	@include breakpoint(mobile) {
		width: 95%;
	}
}

// Mobile only
@include breakpoint(mobile) {
	.page-row-blocks, .flex {
		display: block !important;
	}
}

// Sitekick fixes ONLY
body.sitekick {
	margin-top: 70px;

	.header-wrapper .header-container .col .sidebar {
		overflow: unset;
		top: 0;
		background-color: #fff;
		z-index: unset;
	}

	#sitekick-navbar-shortcuts, #sitekick-navbar-account {
		li.dropdown {
			.dropdown-menu {
				display: none;
			}

			&.open {
				.dropdown-menu {
					display: block;
				}
			}
		}
	}

	.advice-blocks {
		.inline-edit {
			position: unset;

			form {
				color: #000;
			}

			.redactor-box {
				.redactor-toolbar-box {
					top: -150px;
				}
			}
		}
	}

	.what-where-how {
		.redactor-layer {
			color: #fff;
		}
	}

	.redactor-layer {
		.button {
			padding: 13px 70px 8px 20px;
		}
	}

	ul.redactor-toolbar, ul.inline-edit-controls {
		li:before {
			content: none !important;
		}
	}

	.basic-text {
		.inline-edit-controls {
			top: -110px;
		}
	}

	.sitekick a[href^=tel] {
		color: #000;
	}

	.inline-edit form {
		font-family: Arial, sans-serif !important;

        .first-letter-cap p:first-of-type::first-letter {
            font-size: inherit;
			float: none;
			padding: 0;
        }
	}
}