
// Header
header.header {
    position: fixed;
    //visibility: hidden;
    //opacity: 0;
    display: none;
    width: -webkit-calc(100% - 150px);
    width: calc(100% - 150px);
    height: 100%;
    background: #FFF;
    z-index: 99;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;

    @include breakpoint(tablet) {
        width: 100%;
    }

    &._show {
        visibility: visible;
        opacity: 1;
        -webkit-transition: all 0.3s;
        transition: all 0.3s;
        display: block;
    }

    .container-fluid {
        height: 100%;

        .row {
            height: 100%;

            .navigation-block {
                border-top: 8px solid $base-color3;
                border-left: 8px solid $base-color3;
            }

            .col {
                height: 100%;
                padding: 0;
                margin: 0;

                nav {
                    .header-logo {
                        padding: 45px 0 0 40px;

                        @include breakpoint(laptop) {
                            padding: 25px 0 0 20px;
                            position: absolute;
                            top: 0;
                            right: 25px;
                            max-width: 21%;
                        }

                        @include breakpoint(tablet-landscape) {
                            padding: 25px 0 0 20px;
                            max-width: 105px;
                        }

                        @include breakpoint(tablet) {
                            padding: 35px 40px 0 30px;
                            position: absolute;
                            right: 0;
                            bottom: 30px;
                            max-width: 125px;
                        }
                    }

                    ul:not(.sub) {
                        list-style-type: none;
                        padding: 0;
                        margin: 50px 0 0 190px;
                        -webkit-transition: all .3s;
                        -moz-transition: all .3s;
                        -ms-transition: all .3s;
                        -o-transition: all .3s;
                        transition: all .3s;

                        @include breakpoint(laptop) {
                            margin: 150px 60px 50px;
                        }

                        @include breakpoint(tablet-landscape) {
                            margin: 70px 50px;
                        }

                        @include breakpoint(tablet) {
                            margin: 250px 0 0 70px;
                        }

                        @include breakpoint(mobile) {
                            margin: 150px 0 0 25px;
                        }

                        &.minus-top {
                            margin-top: -70px;

                            @include breakpoint(laptop) {
                                margin-top: 50px;
                            }

                            @include breakpoint(tablet-landscape) {
                                margin-top: 0;
                            }

                            @include breakpoint(tablet) {
                                margin-top: 150px;
                            }

                            @include breakpoint(mobile) {
                                margin-top: 50px;
                            }
                        }

                        li {
                            font-size: 36px;
                            font-family: $heading-font-family;
                            padding: 20px 0;

                            @include breakpoint(tablet-landscape) {
                                font-size: 26px;
                                padding: 12px 0;
                            }

                            @include breakpoint(mobile) {
                                font-size: 24px;
                                padding: 13px 0;
                            }

                            a.has-sub {
                                position: relative;

                                // Dropdown arrow down
                                &:before {
                                    content: '';
                                    display: inline-block;
                                    width: 17px;
                                    height: 9px;
                                    background-image: url('../img/icons/arrow-down.svg');
                                    -webkit-background-size: cover;
                                    background-size: cover;
                                    position: absolute;
                                    top: 0;
                                    bottom: 0;
                                    margin: auto;
                                    right: -41px;
                                    -webkit-transition: all .3s;
                                    -moz-transition: all .3s;
                                    -ms-transition: all .3s;
                                    -o-transition: all .3s;
                                    transition: all .3s;
                                }

                                &._toggled:before {
                                    -webkit-transform: rotate(-180deg);
                                    -moz-transform: rotate(-180deg);
                                    -ms-transform: rotate(-180deg);
                                    -o-transform: rotate(-180deg);
                                    transform: rotate(-180deg);
                                }
                            }

                            @include breakpoint(laptop) {
                                font-size: 28px;
                                padding: 10px 0;
                            }

                            .sub {
                                margin-bottom: -22px;
                                margin-top: 10px;
                                padding-left: 20px;
                                list-style-type: none;
                                display: none;

                                @include breakpoint(laptop) {
                                    margin-bottom: -10px;
                                }

                                @include breakpoint(tablet-landscape) {
                                    margin-bottom: -17px;
                                }

                                li {
                                    font-size: 28px;
                                    padding: 15px 0;

                                    @include breakpoint(laptop) {
                                        font-size: 22px;
                                        padding: 10px 0;
                                    }

                                    @include breakpoint(tablet-landscape) {
                                        font-size: 21px;
                                        padding: 10px 0;
                                    }

                                    @include breakpoint(mobile) {
                                        font-size: 18px;
                                        padding: 12px 0;
                                    }
                                }
                            }
                        }
                    }
                }

                .socials {
                    position: absolute;
                    left: 50px;
                    bottom: 50px;
                    font-family: $alt-base-font-family;
                    text-transform: uppercase;
                    letter-spacing: .1em;
                    font-size: 17px;

                    span {
                        float: left;
                        padding-right: 15px;
                    }

                    ul {
                        float: left;
                        list-style: none;
                        padding: 0;
                        margin: 0;

                        li {
                            float: left;
                            margin-right: 13px;

                            img {
                                width: 19px;
                                margin-bottom: -3px;

                                &:hover {
                                    opacity: .8;
                                }
                            }

                            .linkedIn img {
                                margin-left: 2px;
                                margin-bottom: -2px;
                            }
                        }
                    }

                    @include breakpoint(mobile) {
                        left: 25px;
                        bottom: 25px;
                    }
                }

                .header-image {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;

                    @include breakpoint(tablet) {
                        object-position: 71%;
                    }
                }
            }
        }
    }
}