
// Header & Navigation section
.header-wrapper {
    position: relative;
    width: 100%;
    padding: 0;
    height: 66%;
    max-height: 640px;

    @include breakpoint(mobile) {
        height: 70%;
    }

    // Header container
    .header-container {
        height: 100%;

        &.dashboard-header {
            height: 100%;
        }

        .col {
            position: relative;
            height: 100%;
            padding: 0px;

            @include breakpoint(mobile) {
                min-width: 100%;
                min-height: 70%;
                max-height: 78%;
            }

            // Header text
            &.header-text {
                border-top: 8px solid $base-color3;
                border-left: 8px solid $base-color3;

                // Transp. Logo in half
                &:after {
                    content: '';
                    position: absolute;
                    width: 151px;
                    height: 348px;
                    background-image: url('../img/logo-half.png');
                    background-size: cover;
                    right: 0;
                    bottom: 40px;

                    @include breakpoint(laptop) {
                        width: 71px;
                        height: 158px;
                        bottom: 80px;
                    }

                    @include breakpoint(mobile) {
                        bottom: 85px;
                        width: 75px;
                        height: 178px;
                    }
                }

                // Header logo
                .header-logo {
                    padding: 45px 0 0 40px;

                    @include breakpoint(laptop) {
                        position: absolute;
                        right: 25px;
                        padding: 25px 0;
                    }

                    @include breakpoint(tablet-landscape) {
                        padding: 25px 0 0 20px;
                        max-width: 24%;
                    }

                    @include breakpoint(mobile) {
                        padding: 25px 0 0 20px;
                        max-width: 30%;

                        img {
                            max-height: 90px;
                        }
                    }

                    @media (max-width: 375px) {
                        padding: 15px 0 0 10px;
                    }
                }

                // Header inner text
                .header-text-inner {
                    padding: 50px 50px 0 140px;

                    @include breakpoint(laptop) {
                        padding: 70px 40px 0 50px;
                        margin-top: 100px;
                    }

                    @include breakpoint(tablet-landscape) {
                        padding: 20px 20px 0 40px;
                    }

                    @include breakpoint(tablet) {
                        padding: 50px 20px 0 50px;
                    }

                    @include breakpoint(mobile) {
                        padding: 25px 10px 10px 20px;
                    }

                    @media (max-width: 375px) {
                        padding: 0 10px 10px 20px;
                    }

                    h1 {
                        font-size: 48px;
                        line-height: 52px;
                        margin: 0;

                        @include breakpoint(laptop) {
                            font-size: 36px;
                            line-height: 40px;
                        }

                        @include breakpoint(tablet-landscape) {
                            font-size: 34px;
                            line-height: 38px;
                        }

                        @include breakpoint(mobile) {
                            font-size: 32px;
                            line-height: 36px;
                        }

                        &.smaller {
                            @include breakpoint(mobile) {
                                font-size: 28px;
                            }

                            @include breakpoint(laptop) {
                                font-size: 35px;
                            }
                        }
                    }

                    h2 {
                        margin-top: 0;
                        font-family: $alt-heading-font-family;
                        font-size: 27px;
                        font-weight: 400;

                        @include breakpoint(laptop) {
                            font-size: 24px;
                        }

                        @include breakpoint(tablet-landscape) {
                            font-size: 24px;
                            line-height: 36px;
                        }

                        @include breakpoint(mobile) {
                            font-size: 22px;
                            line-height: 29px;
                        }
                    }

                    .button {
                        margin-top: 15px;
                        display: inline-block;
                    }
                }

                // Scroll down button
                .scroll-down {
                    position: absolute;
                    bottom: -35px;
                    right: -55px;
                    box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.17);
                    background-image: url('../img/scroll-down-bg.jpg');
                    -webkit-background-size: cover;
                    background-size: cover;
                    padding: 40px 45px;
                    z-index: 2;

                    @include breakpoint(mobile) {
                        bottom: -40px;
                        right: 20px;
                        padding: 25px 30px;
                    }

                    img {
                        width: 26px;
                        position: relative;
                        -webkit-animation: fadeOut 3s infinite ease-in-out;
                        -o-animation: fadeOut 3s infinite ease-in-out;
                        animation: fadeOut 3s infinite ease-in-out;

                        @include breakpoint(mobile) {
                            width: 20px;
                        }
                    }

                    @include keyframes(fadeOut) {
                        0% { top: -2px }
                        50% { top: 7px; }
                        100% { top: -2px }
                    }
                }
            }

            // Socials
            .socials {
                position: absolute;
                left: 50px;
                bottom: 50px;
                font-family: $alt-base-font-family;
                text-transform: uppercase;
                letter-spacing: .1em;
                font-size: 17px;

                @include breakpoint(mobile) {
                    left: 20px;
                    bottom: 25px;
                    font-size: 14px;

                }

                span {
                    float: left;
                    padding-right: 15px;

                    @include breakpoint(mobile) {
                        padding-right: 10px;
                    }
                }

                ul {
                    float: left;
                    list-style: none;
                    padding: 0;
                    margin: 0;

                    li {
                        float: left;
                        margin-right: 13px;

                        img {
                            width: 19px;
                            margin-bottom: -3px;

                            &:hover {
                                opacity: .8;
                            }

                            @include breakpoint(mobile) {
                                width: 17px;
                            }
                        }

                        .linkedIn img {
                            margin-left: 2px;
                            margin-bottom: -2px;
                        }
                    }
                }
            }

            // Sidebar w/ logo
            .sidebar {
                float: right;
                width: 150px;
                height: 100%;
                position: relative;
                -webkit-transition: all .3s;
                -moz-transition: all .3s;
                -ms-transition: all .3s;
                -o-transition: all .3s;
                transition: all .3s;

                @include breakpoint(tablet) {
                    display: none;
                }

                &:after {
                    content: '';
                    position: absolute;
                    height: 70%;
                    width: 1px;
                    background-color: #9b9898;
                    margin: auto;
                    left: 8px;
                    right: 0;
                    bottom: -385px;

                    @include breakpoint(tablet-landscape) {
                        content: none;
                    }
                }

                img {
                    max-height: 345px;
                    left: 0;
                    right: 0;
                    margin: 190px auto 0;
                    position: absolute;
                    opacity: .6;

                    @include breakpoint(laptop) {
                        max-height: 220px;
                        margin-top: 160px;
                    }

                    @include breakpoint(tablet-landscape) {
                        max-height: 265px;
                        margin-top: 155px;
                    }

                    @include breakpoint(tablet) {
                        max-height: 285px;
                        margin-top: 175px;
                    }
                }

                &._toggled {
                    position: fixed !important;
                    right: 0;
                    top: 0;
                    background-color: #fff;
                    z-index: 2;

                    img {
                        opacity: .9;
                    }

                    &:after {
                        background-color: #676767;
                    }
                }
            }

            .background {
                width: -webkit-calc(100% - 150px);
                width: calc(100% - 150px);

                @include breakpoint(tablet) {
                    width: 100%;
                    background-position: 58%;
                }

                @include breakpoint(mobile) {
                    height: 250px !important;
                    background-position: center center;
                }
            }
        }
    }

    // Header homepage
    &._homepage {
        height: -webkit-calc(50% - 100px);
        height: calc(100% - 100px);
        max-height: none;

        .header-container {
            height: 100%;

            .col {
                @include breakpoint(mobile) {
                    min-width: auto;
                    min-height: auto;
                    max-height: none;

                    .background {
                        max-height: none;
                    }
                }

                // Header text
                &.header-text {
                    .header-text-inner {
                        padding: 50px 70px 0;

                        @include breakpoint(laptop) {
                            padding: 50px 50px 0;
                            margin-top: 140px;
                        }

                        @include breakpoint(tablet-landscape) {
                            padding: 10px 20px 0 40px;
                        }

                        @include breakpoint(tablet) {
                            padding: 55px 20px 0 40px;
                            font-size: 62px;
                            line-height: 64px;
                        }

                        @include breakpoint(mobile) {
                            padding: 10px 10px 10px 20px;
                        }

                        // h1
                        h1 {
                            font-size: 70px;
                            line-height: 74px;
                            font-weight: 600;

                            .underline {
                                z-index: unset;
                            }

                            @include breakpoint(laptop) {
                                font-size: 48px;
                                line-height: 46px;
                            }

                            @include breakpoint(tablet-landscape) {
                                font-size: 48px;
                                line-height: 52px;
                            }

                            @include breakpoint(mobile) {
                                font-size: 34px;
                                line-height: 37px;
                            }
                        }

                        // h2
                        h2 {
                            @include breakpoint(laptop) {
                                font-size: 23px;
                            }

                            @include breakpoint(tablet-landscape) {
                                line-height: 32px;
                                margin-top: 10px;
                            }

                            @include breakpoint(tablet) {
                                margin-top: 8px;
                                font-size: 40px;
                            }

                            @include breakpoint(mobile) {
                                font-size: 25px;
                                line-height: 27px;
                            }
                        }

                        .button {

                            @include breakpoint(laptop) {
                                margin-top: 0;
                            }

                            @include breakpoint(tablet-landscape) {
                                margin-top: 0;
                            }

                            @include breakpoint(mobile) {
                                padding: 13px 60px 13px 13px;
                                font-size: 14px;
                            }
                        }
                    }

                    // Transp. Logo in half
                    &:after {
                        bottom: 100px;

                        @include breakpoint(mobile) {
                            bottom: 145px;
                            width: 75px;
                            height: 178px;
                        }
                    }

                    @include breakpoint(mobile) {
                        .scroll-down {
                            bottom: -40px;
                            right: -40px;
                            padding: 25px 30px;
                        }
                    }
                }

                //
                .sidebar:after {
                    height: 28%;
                    bottom: 50px;

                    @include breakpoint(laptop) {
                        height: 35%;
                        bottom: -60px;
                    }

                    @include breakpoint(tablet-landscape) {
                        content: none;
                    }
                }
            }
        }
    }

    // Navigation button
    .navigation {
        position: fixed;
        right: 0;
        z-index: 2;

        @include breakpoint(tablet-landscape) {
            height: 120px;
        }

        @include breakpoint(tablet) {
            float: right;
            width: 33.5%;
            background-color: #fff;
            height: auto;

            // White bg toggle
            &._white {
                background-color: #fff;
                z-index: 999;
            }

            .contact, .hamburger, & {
                -webkit-transition: all .3s;
                -moz-transition: all .3s;
                -ms-transition: all .3s;
                -o-transition: all .3s;
                transition: all .3s;
            }

            // Sticky toggle
            &._sticky {
                background-color: #fff;
                box-shadow: 0 0 15px 11px rgba(0,0,0,.025);
                z-index: 999;

                .contact {
                    padding: 30px 26px;
                }

                .hamburger {
                    height: 84px;
                }
            }
        }

        @include breakpoint(mobile) {
            width: 50%;
            top: 0;
            background-color: transparent;

            // White toggle
            &._white {
                background: none;
            }

            // Sticky toggle
            &._sticky {
                .contact {
                    padding: 20px 0 0 9.8%;
                    margin-right: 9.8%;
                }

                .hamburger {
                    height: 65px;
                    width: 40.2%;
                }
            }
        }

        // Contact button nav.
        .contact {
            padding: 49px 70px;
            font-family: "din-2014", sans-serif;
            letter-spacing: .1em;
            text-transform: uppercase;
            font-size: 17px;
            background: #fff;
            float: left;
            position: relative;

            a {
                position: relative;
                z-index: 1;

                &:after {
                    content: '';
                    position: absolute;
                    background-color: #ecd6d4;
                    width: 0%;
                    height: 41%;
                    bottom: -1px;
                    left: -7%;
                    right: 0;
                    z-index: -1;
                    -webkit-transition: all .3s;
                    -moz-transition: all .3s;
                    -ms-transition: all .3s;
                    -o-transition: all .3s;
                    transition: all .3s;
                }

                &:hover {
                    &:after {
                        width: 112%;
                    }
                }
            }

            @include breakpoint(laptop) {
                padding: 39px 60px;
            }

            @include breakpoint(tablet-landscape) {
                padding: 48px 63px;
            }

            @include breakpoint(tablet) {
                padding: 49px 25px 49px 0;
                text-align: right;
                width: 50%;
                background: none;

                a {
                    position: relative;
                    z-index: 1;

                    &:after {
                        content: '';
                        position: absolute;
                        background-color: #ecd6d4;
                        width: 112%;
                        height: 41%;
                        bottom: -1px;
                        left: -7%;
                        right: 0;
                        z-index: -1;
                    }
                }
            }

            @include breakpoint(mobile) {
                padding-left: 0;
                padding-right: 0;
                padding-top: 35px;
                font-size: 16px;
                text-align: left;
            }
        }

        // Hamburger menu button
        .hamburger {
            width: 150px;
            height: 120px;
            background: #f8f5f5;
            float: left;
            position: relative;

            @include breakpoint(laptop) {
                height: 100px;
            }

            @include breakpoint(tablet) {
                width: 50%;
            }

            @include breakpoint(mobile) {
                width: 50%;
                height: 90px;
                float: left;
            }

            a {
                position: absolute;
                width: 36px;
                height: 36px;
                border: 1px solid #7c7a7a;
                top: 50%;
                left: 50%;
                -webkit-transform: translateX(-50%) translateY(-50%);
                transform: translateX(-50%) translateY(-50%);

                @include breakpoint(mobile) {
                    width: 32px;
                    height: 32px;
                }

                i {
                    position: absolute;
                    width: 20px;
                    height: 1px;
                    -webkit-transform: translateX(-50%) translateY(-50%);
                    transform: translateX(-50%) translateY(-50%);
                    background: #7c7a7a;
                    left: 50%;
                    top: 50%;

                    -webkit-transition: all .3s;
                    -moz-transition: all .3s;
                    -ms-transition: all .3s;
                    -o-transition: all .3s;
                    transition: all .3s;

                    &:before {
                        content: "";
                        position: absolute;
                        width: 20px;
                        height: 1px;
                        -webkit-transform: translateX(-50%) translateY(-50%);
                        transform: translateX(-50%) translateY(-50%);
                        background: #7c7a7a;
                        left: 50%;
                        top: 50%;
                        margin-top: -7px;
                        -webkit-transition: all .3s;
                        -moz-transition: all .3s;
                        -ms-transition: all .3s;
                        -o-transition: all .3s;
                        transition: all .3s;
                    }

                    &:after {
                        content: "";
                        position: absolute;
                        width: 20px;
                        height: 1px;
                        -webkit-transform: translateX(-50%) translateY(-50%);
                        transform: translateX(-50%) translateY(-50%);
                        background: #7c7a7a;
                        left: 50%;
                        top: 50%;
                        margin-top: 7px;
                        -webkit-transition: all .3s;
                        -moz-transition: all .3s;
                        -ms-transition: all .3s;
                        -o-transition: all .3s;
                        transition: all .3s;
                    }

                    &.active {
                        background: transparent;

                        &:before {
                            -webkit-transform: translateX(-50%) translateY(-50%) rotate(-45deg);
                            -moz-transform: translateX(-50%) translateY(-50%) rotate(-45deg);
                            -ms-transform: translateX(-50%) translateY(-50%) rotate(-45deg);
                            -o-transform: translateX(-50%) translateY(-50%) rotate(-45deg);
                            transform: translateX(-50%) translateY(-50%) rotate(-45deg);
                            margin-top: 0;
                        }

                        &:after {
                            -webkit-transform: translateX(-50%) translateY(-50%) rotate(45deg);
                            -moz-transform: translateX(-50%) translateY(-50%) rotate(45deg);
                            -ms-transform: translateX(-50%) translateY(-50%) rotate(45deg);
                            -o-transform: translateX(-50%) translateY(-50%) rotate(45deg);
                            transform: translateX(-50%) translateY(-50%) rotate(45deg);
                            margin-top: 0;
                        }
                    }
                }
            }
        }
    }
}