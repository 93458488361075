// Colors
$link-color-default:		#000;
$link-color-hover:			#333;
$link-color-active:			$base-color4;
$link-color-visited:		none;

// Decoration
$link-decoration-default:	none;
$link-decoration-hover:		none;
$link-decoration-active: 	none;
$link-decoration-visited: 	none;
